import React, { Component } from 'react'
import { Container, Row, Col, } from 'reactstrap'
import Icon1 from '../../images/svg/bigdata1.svg'
import Icon2 from '../../images/svg/bigdata2.svg'
import Icon3 from '../../images/svg/bigdata3.svg'
import Icon4 from '../../images/svg/bigdata4.svg'
import Icon5 from '../../images/svg/bigdata5.svg'
import Icon6 from '../../images/svg/bigdata6.svg'
import Icon7 from '../../images/svg/bigdata7.svg'
import Icon8 from '../../images/svg/bigdata8.svg'
import Icon9 from '../../images/svg/bigdata9.svg'


export default class index extends Component {
    render() {
        return (
            <div>
                 <section>
            <div className="startup_bg">
              <div className="startup_section">
                {/* <Container>
                  <Row>
                    <Col md="12">
                      <h3>Big Data Staffing</h3>
                      <p>The immense wealth of intelligence captured by enterprises made harnessing and analyzing large data sets the key basis of competition and growth. Big Data can unlock significant value by making information transparent and usable at much higher frequency, expose variability and boost performance, allow ever-narrower customer segmentation, and improve decision-making.</p>
                      <p>Trident’s Big Data Staffing Practice enables our clients to tap into previously underutilized, isolated data silos and help business stakeholders make crucial decisions in daily and long-term business goals.</p>
                      <p>Trident has worked with global organizations in providing effective Big Data consultants that have helped clients to:</p>
                      <ul>
                        <li>Derive revenue growth</li>
                        <li>Reduce costs</li>
                        <li>Identify new business opportunities</li>
                        <li>Respond to competitive threats</li>
                      </ul>

                    </Col>
                  </Row>
                </Container> */}
                <Container>
                  <div className="areas">
                    <div className="area_center">
                      <div className="section_heading">
                        <h3 className="customers_heading">Areas of expertise</h3>
                      </div>
                    </div>
                    <Row>
                      <Col md="4">
                        <div className="area_center">
                          <div className="area_text">
                            <img src={Icon1} alt="img" />
                            <a>Big Data</a>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="area_center">
                          <div className="area_text">
                            <img src={Icon2} alt="img" />
                            <a>Java frameworks and technologies</a>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="area_center">
                          <div className="area_text">
                            <img src={Icon3} alt="img" />
                            <a>AI and Analytics</a>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="area_center">
                          <div className="area_text">
                            <img src={Icon4} alt="img" />
                            <a>Digital Systems and technology</a>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="area_center">
                          <div className="area_text">
                            <img src={Icon5} alt="img" />
                            <a>Cloud</a>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="area_center">
                          <div className="area_text">
                            <img src={Icon6} alt="img" />
                            <a>Infrastructure</a>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="area_center">
                          <div className="area_text">
                            <img src={Icon7}  alt="img"/>
                            <a>Quality engineering and Assurance</a>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="area_center">
                          <div className="area_text">
                            <img src={Icon8} alt="img" />
                            <a>ERP: SAP and Oracle</a>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="area_center">
                          <div className="area_text">
                            <img src={Icon9} alt="img" />
                            <a>Engineering and R&D</a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            </div>
          </section>
                
            </div>
        )
    }
}
